<template>
  <div>
    <!-- 添加图片模块 -->
    <div class="pic_type_wrapper">
      <div class="thumb_item" v-for="item in thumbData" :key="item.fileid">
        <img
          :src="item.url"
          :alt="item.name"
          class="img"
          @click="itemClick(item)"
        />
        <div class="name">{{ item.name }}</div>
        <!-- 删除按钮 -->
        <!-- 编辑模式或非只读模式显示删除按钮 -->
        <div class="delete_icon" v-if="isEditModel && !readonly">
          <svg class="icon" aria-hidden="true" @click="deleteItem(item)">
            <use href="#icon-system_delete_ic"></use>
          </svg>
        </div>
      </div>
      <!-- +新图片 按钮 -->
      <!-- 禁用按钮点击的逻辑
      1、图片数量大于等于限制的数量
      2、只读模式为true -->
      <div class="thumb_item add_pic" v-if="isEditModel">
        <el-button
          type="primary"
          plain
          @click="openUploaddialog"
          :disabled="thumbData.length >= uploadLimit || readonly"
        >
          <i class="el-icon-plus"></i>
          新图片
        </el-button>
      </div>
    </div>

    <!-- 多图片上传弹窗 -->
    <!-- uploadLimit -->
    <MutipleUpload
      :isShowdialog.sync="isShowUploaddialog"
      v-if="isShowUploaddialog"
      :uploadLimit="uploadLimit"
      @uploadFileSuccess="uploadFileSuccess"
      :initUploadThumbList="thumbData"
    />
    <!-- 图片预览组件 -->
    <filePreview
      :show="showPreview"
      :showData="previewData"
      @closePreview="closePreview"
      v-if="showPreview"
      :configButtm="configButtm"
      :isLightningOriginal="isLightningOriginal"
    />
  </div>
</template>

<script>
import axios from "@/config/httpConfig";
export default {
  data() {
    return {
      // 显示图片上传弹窗
      isShowUploaddialog: false,
      // 预览弹窗是否显示
      showPreview: false,
      //   预览的数据
      previewData: [],
      // 上传数量限制
      uploadLimit: 0,
      //   formData当前列的真实数据（JSON格式后的）
      formDataList: [],
      // 配置预览组件显示的按钮
      configButtm: ["download"],
      // 缩略图数据数组
      thumbData: [],
    };
  },
  props: {
    // 父组件的 formAttr 的 item
    item: {
      type: Object,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    // 是否是编辑模式
    isEditModel: {
      type: Boolean,
      default: true,
    },
    // 是否只是显示使用，不带有+新图片按钮和编辑上传功能
    onlyShow: {
      type: Boolean,
      default: false,
    },
    /**
     * 只读模式
     * 1、禁用添加新图片按钮点击事件
     * 2、隐藏图片项目删除按钮
     */
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否是 曹文一的 lightning公式图片的图片
     * 如果是lightning的公式图片预览就需要单独调用曹文一的接口进行预览
     * 预览地址和旧版的预览地址不一样
     */
    isLightningOriginal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MutipleUpload: () => import("@/components/MutipleUpload/index.vue"),
    filePreview: () => import("@/components/FileView/filePreview"),
  },
  created() {
    this.generateThumbData();
  },
  methods: {
    /**
     * 组件初始化时，根据当前 item 数据生成对应的展示数据
     * 和 预览数据的数据格式
     * 和 上传组件需要的数据
     */
    generateThumbData() {
      /**
       * 如果是 lightning静态资源图片
       * 要调用曹文一的静态资源接口获取显示地址
       */
      if (this.isLightningOriginal) {
        let id = this.item.value.split(";")[0];
        if (!id) return;
        /**
         * 这个地址先写死，地址配置和后台设置请求的地址不对应
         */
        axios
          .post(
            `${window.gw["DEV_CONSOLE"]}/devconsole/staticResource/detailStaticResource`,
            { id }
          )
          .then((res) => {
            const { data } = res;
            this.thumbData = [
              {
                id,
                url: data?.cdnfileSource,
              },
            ];
            this.uploadLimit = 1;
          });
      }

      /**
       * 列表页只是显示用
       */
      if (this.onlyShow) {
        let id = this.item.value.split(";")[0];
        if (id) {
          this.thumbData = [
            {
              id,
              url: this.generatePath(id),
            },
          ];
          this.uploadLimit = 1;
        }
      } else {
        /**
         * 编辑页，需要更新图片或删除图片
         */
        let { data } = this.item;
        data = data || [];
        let thumbData = data.map((itemX) => {
          return {
            id: itemX.id,
            name: `${itemX.name}.${itemX.type.toLowerCase()}`,
            size: this.Byte2Kb(itemX.filesize),
            url: this.generatePath(itemX.id),
            fileType: itemX.type.toLowerCase(),
          };
        });

        this.thumbData = thumbData;

        const { defaultValue } = this.item;
        this.uploadLimit = defaultValue === "" ? 1 : Number(defaultValue);
      }
    },
    // 生成预览缩略图地址
    generatePath(id) {
      return `${
        this.$baseConfig.baseURL
      }/file/viewImg?id=${id}&accessToken=${this.$CCDK.CCToken.getToken()}`;
    },
    /**
     * 缩略图 删除
     * item : 当前点击的 item 图片对象
     */
    deleteItem(item) {
      const thumbData = this.thumbData || [];
      thumbData.forEach((fileItem, index) => {
        if (fileItem.id === item.id) {
          this.thumbData.splice(index, 1);
        }
      });

      /**
       * 将上传组件传递的上传完成后的id写给表单
       * 删除操作也要发送事件，更新表单上存储的id表单数据
       */
      const { prop } = this.item;
      this.$emit("uploadFileSuccess", { data: this.thumbData, prop });
    },

    /**
     * 打开上传弹窗
     * item : 当前点击的 表单列字段info
     */
    openUploaddialog() {
      this.isShowUploaddialog = true;
    },
    /**
     * 打开弹窗前，检测可上传数量
     * 赋值实例变量
     */
    // 文件上传成功
    uploadFileSuccess({ data }) {
      // prop, fileId, isPng
      // if (this.formData[prop] === undefined || this.formData[prop] === "") {
      //   this.$set(this.formData, prop, fileId);
      // } else {
      //   if (isPng) {
      //     this.$set(this.formData, prop, fileId);
      //   } else {
      //     this.$set(this.formData, prop, `${this.formData[prop]}${fileId}`);
      //   }
      // }
      // this.filedId = fileId;

      // 上传成功后，数据回显，没有URL，需要增加URL字段
      this.thumbData = data.map((item) => {
        return {
          ...item,
          url: this.generatePath(item.id),
        };
      });

      // 将上传组件传递的上传完成后的id写给表单
      const { prop } = this.item;
      this.$emit("uploadFileSuccess", { data: this.thumbData, prop });
      // 关闭dialog
      this.isShowUploaddialog = false;
    },
    // 字节转KB
    Byte2Kb(byte) {
      return Number((Number(byte) / 1024).toFixed(2));
    },
    // 缩略图点击
    itemClick(item) {
      // item ： 当前点击的
      // 缩略图列表

      const thumbData = this.thumbData || [];
      let data = thumbData.map((itemx) => {
        if (itemx.id === item.id) {
          return {
            ...itemx,
            active: true,
            id: itemx.id,
            fileType: itemx.type,
            isUseNativePreview: true,
          };
        }

        return {
          ...itemx,
          active: false,
          id: itemx.id,
          fileType: itemx.type,
          isUseNativePreview: true,
        };
      });
      /**
       * 预览的列表
       * 单/多预览都使用此 key
       */
      this.previewData = data;

      this.showPreview = true;
    },
    // 关闭预览
    closePreview() {
      this.showPreview = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// 图片类型盒子
.pic_type_wrapper {
  border: 1px solid #e1e1e1;
  padding: 8px;
  min-height: 52px;
  row-gap: 4px;
  column-gap: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));

  .thumb_item {
    border: 1px solid #e1e1e1;
    height: 34px;
    line-height: 34px;
    display: flex;

    > img {
      width: 32px;
      height: 32px;
      margin-right: 8px;
      cursor: pointer;
    }
    &.add_pic {
      border: 1px solid transparent !important;
      // padding: 9px 15px!important;
      ::v-deep .el-button {
        padding: 9px 15px !important;
      }
    }

    .name {
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }
    .delete_icon {
      width: 20px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>